<template>
  <RealisasiKpiKorporatForm mode="Ubah" module="Realisasi Indikator Kinerja"> </RealisasiKpiKorporatForm>
</template>

<script>
import RealisasiKpiKorporatForm from './form-realisasi';

const RealisasiKpiKorporatUpdate = {
  name: 'RealisasiKpiKorporatUpdate',
  components: { RealisasiKpiKorporatForm },
};

export default RealisasiKpiKorporatUpdate;
</script>
